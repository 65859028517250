import React from 'react'
import Hero from '../../components/Hero'
import Layout from '../../components/layout'
import { graphql, Link } from 'gatsby'
import './blog.css'
import { Img } from '@tueri/react'

export default function Blog({ data: { allMarkdownRemark: { edges } } }) {

    return(
        <Layout>
            <Hero 
                title='From the blog'
            />
            {
                edges.map((edge, i) => {
                  const post = edge.node.frontmatter
                  return (
                    <div className='blogPost__container'>
                      <Link to={ edge.node.fields.slug } className='noUnderline'>
                          <h3 className='blogPost__title'>
                              <div className='blogPost__date'>{ post.date }</div>
                              { post.title }
                          </h3>
                          <div className='blogPost__cover'>
                              <Img src={ post.cover } alt={ post.title }/>
                          </div>
                          <div className='blogPost__excerpt'>
                              { post.excerpt || edge.node.excerpt }
                          </div>
                      </Link>
                      <Link className='readMore' to={ edge.node.fields.slug }>Read more</Link>
                    </div>
                  )
                })
            }
        </Layout>
    )
}

export const pageQuery = graphql`
  query {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1000
      ) {
        edges {
          node {
            excerpt
            fields { slug }
            frontmatter {
              title
              subTitle
              cover
              date(formatString: "LL")
            }
          }
        }
      }
    }
`
